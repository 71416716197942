import { createI18n } from 'vue-i18n'; 
import demo from './modules/demo'
import main from './modules/main'
const modules:any={
	demo,main
}
/*
const modulesFiles3 = import.meta.globEager('./modules/*.js');
for (const modulePath in modulesFiles3) {
	const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1').replace('modules/','') 
	modules[moduleName] = modulesFiles3[modulePath].default;
}
*/
function format(str:string,...args:any[]){
	return str.replace(/<(\d+)>/g, function(match, number) {
		return typeof args[number] !== 'undefined' ? args[number] : match
	})
}
var wyLocale={
	localeChangeEvent:function(key:string){

	},
    keyEncode:function(key:string){ 
        return key;//encodeURIComponent(key)
    },
    setOption(options:any){
        let {keyEncode}=options
        if(keyEncode && typeof keyEncode =='function'){
            wyLocale.keyEncode=keyEncode
        } 
    },
	localeFiles:modules,
    /**
     * 翻译文件
     * @param {Array} localesArr 外部定义翻译文件
     * @param {String} defaultLang 作为键值的语言
     * @param {Boolean} useWylocale 是否使用插件自带翻译包
     */
	localeParse:function(localesArr:any=[],defaultLang:string='zh-Hans',useWylocale=true){
		if(useWylocale){ 
            localesArr=[modules].concat(localesArr)
		}
		let localeObj: { [key: string]: any } = {}; 
		for(let i=0;i<localesArr.length;i++){
			var locales=localesArr[i]
			for(let key in locales){
				//key app
				let {keys,lang,translate}=locales[key]
               // console.log(locales[key])
				if(keys&&lang){
					keys.forEach((k:string)=>{
						if(!localeObj[k]){
							localeObj[k]={}
						}
					}) 
					var defaultLangIndex=keys.findIndex((e:string)=>{
						return e===defaultLang
					}) 
                    if(defaultLangIndex<0){
                        defaultLangIndex=0
                    }
					for(let k in lang){
						//k login
						let second=lang[k]
						for(let k2 in second){
							//k2 register
							let valueData=second[k2]
							if(valueData){
								if(k2=='_translate'){
									var _translate=valueData
									for(let i=0;i<_translate.length;i++){
										var values=_translate[i]
										if(values.length>0){
											for(let i=0;i<keys.length;i++){
												let langKey=keys[i]
												var localeKey=''
												if(defaultLangIndex>=0 && values.length>defaultLangIndex){
													localeKey=[k,wyLocale.keyEncode(values[defaultLangIndex])].join('.')
												}
												if(!localeKey){
													localeKey=[k,wyLocale.keyEncode(values[0])].join('.')
												}
												var val=values.length>i?values[i]:values[0]
												localeObj[langKey]=Object.assign(localeObj[langKey],{[localeKey]:val})
											}
										}

									}
								}
								else{
									var values=valueData
									let localeKey=[key,k,k2].join('.')
									var langkey2=''
									if(defaultLangIndex>=0 && values.length>defaultLangIndex){
										langkey2=[k,wyLocale.keyEncode(values[defaultLangIndex])].join('.')
									}
									if(values.length>0){
										for(let i=0;i<keys.length;i++){
											let langKey=keys[i]
											var val=values.length>i?values[i]:values[0]
											localeObj[langKey]=Object.assign(localeObj[langKey],{[localeKey]:val})
											if(langkey2){
												localeObj[langKey]=Object.assign(localeObj[langKey],{[langkey2]:val})
											}
										}
									}
								}
							}
						}
					}
					if(translate){
						for(let i=0;i<translate.length;i++){
							var values=translate[i]
							if(values.length>0){
								for(let i=0;i<keys.length;i++){
									let langKey=keys[i]
									var localeKey=''
									if(defaultLangIndex>=0 && values.length>defaultLangIndex){
										localeKey=wyLocale.keyEncode(values[defaultLangIndex])
									}
									if(!localeKey){
										localeKey=wyLocale.keyEncode(values[0])
									}
									var val=values.length>i?values[i]:values[0]
									localeObj[langKey]=Object.assign(localeObj[langKey],{[localeKey]:val})
								}
							}

						}
					}
				}
			}
		}

		return localeObj
	},
	/**
	 * 翻译
	 * @param {*} key 值
	 * @param {*} isContent 是否传入内容作为键值
	 * @param {*} group 分组
     * @param {*} file 文件名称，当isContent为false时必传
	 */
	localeDo:function(key:string,group='',args:any=[],isContent=false,file=''){
		return key
	},
	localeChange(lang:string){

	},
	/**
	 * 初始化 返回localeDo 方法
	 * @param {*} messages 词库
	 */
	localeInit:function(defaultLang='zh-Hans',indexLang='zh-Hans',messages:any=null){ 
		if(!messages){
			messages=wyLocale.localeParse(modules,indexLang)
		} 
		for(let key in messages){
			for(let sk in messages[key]){
				messages[key][sk]=messages[key][sk].replace('{','<').replace('}','>')
			}
		}
		const i18n = createI18n({
			messages,locale:defaultLang,
			legacy: false,
			silentTranslationWarn:true
		})
		wyLocale.localeChange=function(lang:string){
			i18n.global.locale.value=lang
			wyLocale.localeChangeEvent(lang)
		}
		wyLocale.localeDo= function(key:string,group='',args:any=[],isContent=false,file=''){
			var skey=key
			if(isContent){
				skey=wyLocale.keyEncode(key)
			} 
			if(group){
				skey=group+'.'+skey
			}
            if(!isContent && file){
                skey=file+'.'+skey
            }  
			let val:string=(i18n.global.t(skey) as string);
			if(val===skey ){
				if(group){  
					val =  wyLocale.localeDo(key,'',args,isContent,file)
				}
				else{
					val = key
				}
			} 
			//console.log(skey,val)
			//return val
			return format(val,...args)
		}
		return wyLocale.localeDo
	}
}
export default wyLocale
