import http from '../request';

// 获取首页轮播图列表
export const getPicplayer: any = () => {
  return http.request({
    url: 'api.php?c=data&unicode=picplayer',
    method: 'get'
  });
};
// 获取首页定制服务
export const getService: any = () => {
  return http.request({
    url: 'api.php?c=data&unicode=service',
    method: 'get'
  });
};
// 获取首页合作伙伴
export const getPartners: any = () => {
  return http.request({
    url: 'api.php?c=data&unicode=hezuohuoban',
    method: 'get'
  });
};
// 获取首页解决方案类型
export const getSolutionCate: any = () => {
  return http.request({
    url: 'api.php?c=data&unicode=solution_cate',
    method: 'get'
  });
};
// 获取首页解决方案列表
export const getSolution: any = (id) => {
  return http.request({
    url: 'api.php?c=data&unicode=solution&cate_id=' + id,
    method: 'get'
  });
};
// 获取首页咨询中心类型
export const getCatelist: any = () => {
  return http.request({
    url: 'api.php?c=data&unicode=news_catelist',
    method: 'get'
  });
};
// 获取首页解决方案列表
export const getTitlelist: any = (id) => {
  return http.request({
    url: 'api.php?c=data&unicode=titlelist&cate_id=' + id,
    method: 'get'
  });
};
// 获取首页关于我们
export const getAboutus: any = () => {
  return http.request({
    url: 'api.php?c=data&unicode=aboutus',
    method: 'get'
  });
};
// 获取首页联系我们
export const getContactus: any = () => {
  return http.request({
    url: 'api.php?c=data&unicode=contactus',
    method: 'get'
  });
};
// 获取定制服务分类
export const getSerInfomation: any = (id) => {
  return http.request({
    url: 'api.php?c=data&unicode=ssh93s2zb7&cate_id=' + id,
    method: 'get'
  });
};
// 获取为什么选择我们
export const getChoice: any = () => {
  return http.request({
    url: 'api.php?c=data&unicode=cst0cjirkh',
    method: 'get'
  });
};
// 获取其他页面轮播图
export const getOtherBanner: any = (id) => {
  return http.request({
    url: 'api.php?c=data&unicode=zhn5mp0brm&cate_id=' + id,
    method: 'get'
  });
};
// 获取开发流程
export const getFlow: any = () => {
  return http.request({
    url: 'api.php?c=data&unicode=cycfssmyty',
    method: 'get'
  });
};



