import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
// import { closeGlobalLoading } from '@/utils/common';
const Index = () => import('@/views/index/index.vue');
const Customized = () => import('@/views/customized/index.vue');
const Solution = () => import('@/views/solution/index.vue');
const News = () => import('@/views/news/index.vue');
const About = () => import('@/views/about/index.vue');
const Detail = () => import('@/views/detail/index.vue');

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Index',
    meta: {
      title: '首页',
      keepAlive: true,
      isShowComNav: false, // 是否显示公共的导航栏
      requireLogin: false
    },
    component: Index
  },{
    path: '/customized',
    name: 'Customized',
    meta: {
      title: '定制服务',
      keepAlive: true,
      isShowComNav: false,
      requireLogin: true
    },
    component: Customized
  },{
    path: '/solution',
    name: 'Solution',
    meta: {
      title: '解决方案',
      keepAlive: true,
      isShowComNav: false,
      requireLogin: true
    },
    component: Solution
  },{
    path: '/news',
    name: 'News',
    meta: {
      title: '解决方案',
      keepAlive: true,
      isShowComNav: false,
      requireLogin: true
    },
    component: News
  },{
    path: '/about',
    name: 'About',
    meta: {
      title: '关于未耘',
      keepAlive: true,
      isShowComNav: false,
      requireLogin: true
    },
    component: About
  },{
    path: '/detail',
    name: 'Detail',
    meta: {
      title: '详情',
      keepAlive: true,
      isShowComNav: false,
      requireLogin: true
    },
    component: Detail
  }
];
// const routerHistory = createWebHistory('/');
const router = createRouter({
  history: createWebHistory(),
  routes
});

// 全局守卫：登录拦截 本地没有存token,请重新登录
router.beforeEach((to, from, next) => {
	next();
  // console.log(to, from);
  // const token = localStorage.getItem('token');
  // const userInfo = localStorage.getItem('userInfo');
  // // 需要权限且已经登录
  // if (to.meta.requireLogin && !token) {
  //   closeGlobalLoading(); // 关闭全局等待层
  //   LoginDialog(true, to.fullPath);
  // } else if (to.meta.requireLogin && token) {
  //   // 需要权限且已经登录
  //   if (userInfo) {
  //     const emailVerify = JSON.parse(userInfo as string).auth.email.valid;
  //     if (emailVerify) {
  //       next();
  //     } else {
  //       router.push({
  //         path: '/emailVerify',
  //         query: {
  //           email: JSON.parse(userInfo as string).email
  //         }
  //       });
  //       closeGlobalLoading(); // 关闭全局等待层
  //     }
  //   } else {
  //     closeGlobalLoading(); // 关闭全局等待层
  //     LoginDialog(true, to.fullPath);
  //   }
  // } else {
  //   next();
  // }
});
export default router;
