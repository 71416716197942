<!-- 首页标题栏 -->
<template>
	<div class="nav-bar-box">
		<logo-com :icon-color="iconColor ? iconColor : '#fff'" :font-color="fontColor ? fontColor : '#fff'"></logo-com>
		<div class="center">
			<el-menu :default-active="route.name" class="el-menu-demo" mode="horizontal" :ellipsis="false"
				@select="handleSelect">
				<template v-for="(item, index) in menuList" :key="index">
					<el-sub-menu v-if="item.title == '定制服务'">
						<template #title>{{$t(customizedTitle == '' ? '定制服务' : customizedTitle,'nav')}}</template>
						<el-menu-item v-for="(etem, index) in serviceList" :key="etem.id" :index="etem.title" @click="handleClose(etem)">{{$t(etem.title,'nav')}}</el-menu-item>
					</el-sub-menu>
					<el-menu-item :index="item.name" v-else>{{ $t(item.title,'nav') }}</el-menu-item>
				</template>
			</el-menu>
		</div>
		<div class="right" style="padding-left:10px;">
			<el-menu class="el-menu-demo" mode="horizontal" :ellipsis="false" @select="langSelect"
				:default-active="currentLang">
				<el-sub-menu popper-class="navbar-popper-box" :show-timeout="0" :hide-timeout="100">
					<template #title>{{ $t(appStore.useLocaleStore.localeTitle,'nav') }}</template>
					<template v-for="(item, index) in langList" :key="index">
						<el-menu-item :index="item.name">{{$t(item.title,'nav')}}</el-menu-item>
					</template>
				</el-sub-menu>
			</el-menu>
		</div>
	</div>
</template>
<script setup lang="ts">
	import { getCurrentInstance } from 'vue';
	import { getService } from '@/http/api/weiyun';
	import appStore from '@/store';
	const instance = getCurrentInstance();
	const $localeChange = instance?.appContext.config.globalProperties.$localeChange;
	const langList = appStore.useLocaleStore.langList
	const currentLang = ref<string>('')
	const customizedTitle = ref<string>('')
	const langSelect = (key : string) => {
		let index = langList.findIndex((ee) => {
			return ee.name == key
		})
		if (index >= 0) {
			currentLang.value = langList[index].title
			$localeChange(key)
		}
	};
	interface IBgcColor {
		bgColor ?: string;
		fontColor ?: string;
		iconColor ?: string;
		position ?: string;
	}
	const route = useRoute();
	const props = withDefaults(defineProps<IBgcColor>(), {
		bgColor: '',
		fontColor: '',
		iconColor: '#fff',
		position: 'fixed'
	});

	// 菜单列表
	const menuList = reactive([
		{
			iconfont: '',
			name: 'Index',
			title: '首页',
		},
		{
			iconfont: '',
			name: 'Customized',
			title: '定制服务',
		},
		{
			iconfont: '',
			name: 'Solution',
			title: '解决方案',
		},
		{
			iconfont: '',
			name: 'News',
			title: '咨讯中心',
		},
		{
			iconfont: '',
			name: 'About',
			title: '关于未耘',
		}
	]);

	// 菜单
	const currentMenu = ref<string>('');
	const handleSelect = (key : string) => {
		currentMenu.value = key;
		if(key == 'Index' || key == 'Solution' || key == 'News' || key == 'About'){
			router.push({
				name: key
			});
		}
	};
	const handleClose = (item : object) => {
		customizedTitle.value = item.title;
		router.push({
			name: 'Customized',
			query: {
				id: item.id
			}
		});
	};
	

	const router = useRouter();
	// 获取定制服务信息
	const serviceList = ref<Array<any>>([]);
	const getServiceList = async () => {
		const data = await getService();
		serviceList.value = data.info;
	};
	getServiceList();
</script>
<style lang="scss" scoped>
	.nav-bar-box {
		background: white;
		display: flex;
		height: 80px;
		width: 100%;
		box-sizing: border-box;
		align-items: center;
		justify-content: space-between;
		backdrop-filter: blur(8px);
		background-color: v-bind('props.bgColor');
		z-index: 10;
		user-select: none;
		padding: 0 60px;
		position: v-bind('props.position');
		top: 0;
		transition: all 0.3s;

		.center {
			flex: 1;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;

			.el-menu {
				border: none;
				height: 100%;
				background-color: rgba(255, 255, 255, 0);
				display: flex;
				justify-content: center;
				align-items: center;

				.el-menu-item {
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
					color: v-bind('props.fontColor');
					padding: 0 15px !important;
					letter-spacing: 3px;
					font-size: 16px;
					border-bottom: 4px solid transparent;
					transition: all 0.3s;

					&:hover {
						border-color: #2F7FB9;
						background-color: rgba(#ccc, 0.1);
					}
				}

				.el-sub-menu {
					height: 100%;
					color: v-bind('props.fontColor');
					border-bottom: 4px solid transparent;

					&:hover {
						border-bottom: 4px solid #2F7FB9 !important;
						background-color: rgba(#ccc, 0.1);
					}

					:deep(.el-sub-menu__title) {
						letter-spacing: 3px;
						font-size: 16px;
						color: v-bind('props.fontColor');
						border: none;

						&:hover {
							background-color: rgba(#ccc, 0.1);
						}
					}
				}

				.is-active {
					background-color: rgba(255, 255, 255, 0);
					color: #2F7FB9;
					border-bottom: 4px solid #2F7FB9 !important;
				}
			}
		}

		.right {
			display: flex;
			align-items: center;

			.attendance-total {
				font-size: 12px;
				color: v-bind('props.fontColor');
				margin-right: 20px;
				letter-spacing: 2px;
			}

			.contact-me {
				cursor: pointer;
				margin-right: 15px;
				font-size: 14px;
				color: v-bind('props.iconColor');
			}

			.svg-icon {
				cursor: pointer;
			}

			.attendance-box {
				margin-right: 10px;

				.button {
					padding: 6px 9px 6px 12px;
					display: flex;
					align-items: center;
					justify-content: center;
					align-items: center;
					border: 1px solid v-bind('props.fontColor');
					text-align: center;
					color: v-bind('props.fontColor');
					letter-spacing: 4px;
					font-size: 13px;
					border-radius: 15px;
					overflow: visible;
					cursor: pointer;
					-webkit-transition: all 0.2s;
					-moz-transition: all 0.2s;
					-ms-transition: all 0.2s;
					transition: all 0.2s;
					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
					transition: all 0.3s;

					&:hover {
						opacity: 0.7;
					}
				}

				.have-attend {
					border: 1px solid v-bind('props.fontColor');
					color: v-bind('props.fontColor');
					padding: 6px 9px;
					display: flex;
					align-items: center;
					justify-content: center;
					align-items: center;
					text-align: center;
					letter-spacing: 4px;
					font-size: 13px;
					border-radius: 15px;
					overflow: visible;
				}
			}

			.get-source-code {
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0 10px;
				cursor: pointer;
				transition: all 0.3s;

				&:hover {
					opacity: 0.9;
				}

				.content-box {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 5px 10px;
					background-color: #2F7FB9;
					border-radius: 15px;

					span {
						font-size: 12px;
						letter-spacing: 1px;
						color: #2F7FB9;
						margin: 2px 0 0 4px;
					}

					.svg-icon {
						margin-right: 5px;
					}
				}
			}

			.jb-num-box {
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0 10px;
				cursor: pointer;
				transition: all 0.3s;

				&:hover {
					opacity: 0.9;
				}

				.content {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 5px 10px;
					background-color: #2F7FB9;
					border-radius: 15px;

					span {
						margin-left: 5px;
						font-size: 12px;
						letter-spacing: 1px;
						color: #2F7FB9;
						margin-top: 2px;
					}

					img {
						margin-right: 5px;
					}
				}
			}
		}
	}
</style>
<style lang="scss">
	.navbar-popper-box {
		overflow: hidden;
		border: none;
		border-radius: 0;

		.el-menu {
			padding: 0;
			min-width: 134px;

			.el-menu-item {
				height: 50px;
				font-size: 14px;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				letter-spacing: 2px;
			}
		}
	}
</style>