import { defineStore } from 'pinia';

// 用户信息
export const useLocaleStore = defineStore('localeStore', () => {
  let localeVal = localStorage.getItem('locale')
  let localTitle = '中文'
  localeVal=localeVal?localeVal:'zh-Hans'
  const langList = reactive([
    {name:'zh-Hans',title:'中文'},
    {name:'en',title:'english'},
  ])
  var index=langList.findIndex((e)=>{
    return e.name == localeVal
  })
  if(index>=0){
    localTitle=langList[index].title
    localeVal=langList[index].name
  }

  const locale = ref<string>(localeVal);
  const localeTitle =ref<string>(localTitle);
  function saveLocale(localeStr: string) {
    locale.value= localeStr
    var index2=langList.findIndex((e)=>{
        return e.name == localeStr
    })
    if(index2>=0){ 
        localeTitle.value =langList[index2].title
    } 
    localStorage.setItem('locale', locale.value); 
  }
  return {
    locale,localeTitle,langList,
    saveLocale
  };
});
